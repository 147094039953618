import React from "react";
import Grid from '@mui/material/Grid';
import { useContext,useEffect } from 'react';
import Partners from "./Partners";
import versions_manager_img from '../assets/images/versionsmanager5.png';
import { useTranslation } from "react-i18next";
import social_keep_main from '../assets/images/social_keep_main.png';
import wire_main from '../assets/images/wire_main.png';
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import AuthContext from "./Usecontexts/Maincontext";



//create new component

const Projects = () => {

    const theme = useTheme();
    const { t } = useTranslation();
    const up_then_md=  useMediaQuery(theme.breakpoints.up('md'));
    const {currentlang}=useContext(AuthContext);

    



    useEffect(() => {

    

    }, [])

    const myprojects = [
        {
            name:t('Social Keeper'),
            description:t('Social Keeper_description'),
            image:social_keep_main,
            partnertype:'project',
            link:'https://github.com/dor933/socialkeeper2'
        },
        {
            name:t('Wire7ack'),
            description:t('Wire7ack_description'),
            image:wire_main,
            partnertype:'project' ,
            link:'https://github.com/dor933/Wire7ack_new'
        },
        {
          name:t('versions_manager'),
          description:t('versions_manager_description'),
            image:versions_manager_img,
            partnertype:'project',
            link:"https://github.com/dor933/version_manager"

        } 
     
        

     
   
       

    ]

    return (
        <>
         <Grid container id="partners" style={{

display: 'flex',
paddingTop:'100px',
paddingBottom:'100px',
paddingLeft:up_then_md?'40px':'10px',
paddingRight:up_then_md?'40px':'10px',
flexDirection: 'column',
justifyContent: 'center',
alignItems:'flex-start',
background: 'var(--BG-Shade, #F5FCFF)',
gap:"60px"


}}>

<Grid item xs={12} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems:'flex-start',
                alignSelf:'stretch',
                gap:'22px',
            }}>
                  <div
                style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto',fontSize:'21.333px',fontWeight: currentlang==='he'? 600: 500,marginBottom:'5px', color:'#282938',lineHeight:"150%", fontStyle:'normal',letterSpacing:'0.15px'}}
                
                >


                     {t('businesses_solutions')}                    
                    
                </div>
                <div
                style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto',fontSize:'63.333px',fontWeight: currentlang==='he'? 700: 600,marginBottom:'10px', color:'#282938',lineHeight:"120%", fontStyle:'normal',letterSpacing:'0.15px'}}
                
                >

                    {t('business_partners')}
                </div>

                </Grid>

                <Grid container style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems:'center',
                            gap:"20px",
                            flexDirection: 'row',
                            alignSelf:'stretch',
            
                        }}>

                {

                   myprojects.map((item) => {

                    return (
                  
                        <Partners
                        name={item.name}
                        description={item.description}
                        image={item.image}
                        partnertype={item.partnertype}
                        link={item.link}
                        />

                    )
                   })


                }

</Grid>




    </Grid>
    
        </>
    );
};

export default Projects;