import React from "react";
import Grid from '@mui/material/Grid';
import open_in_new from '../assets/images/open_in_new.png';
import { useEffect , useContext} from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from "./Usecontexts/Maincontext";
import { useTheme } from '@mui/material/styles';




const Partners = ({name,image,description,partnertype,link}) => {

    const [isvisible2, setIsvisible2] = React.useState(false);
    const { t } = useTranslation();
    const {currentlang}= useContext(AuthContext);
    const isxl= useTheme().breakpoints.up('xl')

    

    
    const handleScroll = () => {
        const element = document.getElementById("contact2");
        const rect = element.getBoundingClientRect();
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    
        // Check if the component is within the viewport
        if (window.scrollY > 1080 && window.scrollY < 5000) {
          setIsvisible2(true);
        } else {
          setIsvisible2(false);
        }
      };
    
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        // Run scroll handler on mount to check if component is in view
        handleScroll();
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
  


    return (

        <Grid id="contact2" xs={12} sm={8} md={5} lg={3}  container style={{
            display:'flex',
            paddingBottom:'22px',
            gap:'10px',
            borderRadius:"10px",
            borderBottom:"4px solid #F5FCFF",
            backgroundColor:"#F5FCFF",
            height:currentlang==='he'? '620px': '700px',
            boxShadow:"0px 0px 32px 0px rgba(0, 0, 0, 0.15)",
            transform: isvisible2 ? '' : !isvisible2 && currentlang==='he'? 'translateX(120%)': !isvisible2 && currentlang==='en'? 'translateX(-120%)':'',
            transition: 'opacity 3s ease-in-out,transform 2s ease-in-out',
            opacity: isvisible2 ? 1 : 0,


        }}>

            <Grid xs={12} container style={{

                display:'flex',
                flexDirection:'row',
                
            }}

            >

                <Grid xs={12} style={{
                    display:'flex',
                    gap:'14px',
                    borderRadius:'10px',
                    backgroundColor:"#FFF",
                    
                }}>

                    <img src={image} alt="symbol" style={{width:'100%',height:'100%',objectFit:'cover', borderRadius:'8px'}}/>


                </Grid>

                </Grid>

                <Grid xs={12} style={{ flexDirection:'column', justifyContent:'flex-start' ,alignItems:'flex-start', alignSelf:'stretch', gap:'21px',paddingRight:'20px',paddingLeft:'20px', height:"100%",display:'flex'}} >

                    <div style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', color:'#282938', fontSize:'28px', fontWeight:600, fontStyle:'normal',lineHeight:"140%"}}>

                    {name}
                    </div>

                    <div style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', color:'#282938', fontSize:'18px', fontWeight:400, fontStyle:'normal',lineHeight:"150%"}}>

                        
                    {description}

                    </div>

                    <div onClick={()=> {

                 window.open(link);

                    }} style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', color:'#282938', fontSize:'18px', fontWeight:600, fontStyle:'normal',lineHeight:"150%" ,
                borderBottom: '1.333px solid var(--Primary, #5E3BEE)', paddingBottom:'10px', display:'flex',
                alignItems:'center', cursor:'pointer'
                }}>

                        
                        <span>
                            
                                {
                                partnertype==='wordpress'? t('enter_website') : partnertype==='project'? t('enter_repo')
                                :''
 }
                                
                            

                        </span>
                        <img src={open_in_new} alt="symbol" style={{width:'20px',height:'20px',objectFit:'cover', padding:'5px',marginTop:'1px'}}/>
                 

                        
</div>

                    

                  
                </Grid>
                


            </Grid>


      


    )
}

export default Partners;