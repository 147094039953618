import React from "react";
import Grid from '@mui/material/Grid';
import Product from "./Product";
import prod_chain1 from '../assets/images/prod_chain1.png';
import tag1 from '../assets/images/tag1.png';
import web_pen from '../assets/images/web_pen.png';
import feather_pen from '../assets/images/feather_pen.png';
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import {content_writing,website_building,maintainece,organic_promotion, 
content_writing_small,website_building_small,maintainece_small,organic_promotion_small

} from '../text'
import { useTranslation } from 'react-i18next';
import AuthContext from "./Usecontexts/Maincontext";


export default function Package() {

    const { t } = useTranslation();
    const {currentlang}= React.useContext(AuthContext)

    

    const theme = useTheme();
    const isxs= useMediaQuery(theme.breakpoints.down('xs'));
    const up_thenmd= useMediaQuery(theme.breakpoints.up('md'));

    const firstskill={
        title:t("cybersecurity"),
        description:t("cybersecurity_description"),
        image:tag1,
        description_small:t("cybersecurity_small")
    }

    const secondskill={
        title:t("software_development"),
        description:t("software_development_description"),
        image:web_pen,
        description_small:t("software_development_small")
    }

    const thirdskill={
        title:t("databases"),
        description:t("databases_description"),
        image:feather_pen,
        description_small:t("databases_small")
    }

    const fourthskill={
        title:t("systems_administration"),
        description:t("systems_administration_description"),  
        image:prod_chain1,
        description_small:t("systems_administration_small")
    }

    const skills=[firstskill,secondskill,thirdskill,fourthskill]
//create a variable that will represent lg screen
const isLg = useMediaQuery(theme.breakpoints.up('lg'));


    return (
        <Grid id="package" container style={{

            display: 'flex',
            paddingTop:'150px',
            paddingBottom:'150px',
            paddingLeft:isxs?'10px':'7px',
            paddingRight:isxs?'10px':'7px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems:'flex-start',

        }}>

            <Grid container item xs={12} style={{

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems:'flex-start',
                gap:"21px",


            }}
            >

                <Grid item xs={12} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems:'center',
                    gap:"10px"
                }}>
                    <div style={{
                        textAlign:'center',
                        fontFamily: currentlang==='he'? 'Assistant': 'Roboto',
                        fontWeight: currentlang==='he'? 600: 500,
                        lineHeight:"150%",
                        color:"#282938",
                        fontSize:'21.333px'
                    }}>
                        {t('building_website_alone')}
                    </div>

                    </Grid>

                    <Grid item xs={12} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems:'center',
                        gap:"10px"
                    }}>

                
                <div style={{
                   

                    fontFamily: currentlang==='he'? 'Assistant': 'Roboto',
                    fontWeight: currentlang==='he'? 700: 600,
                    lineHeight:"120%",
                    color:"#282938",
                    fontSize:up_thenmd? "64px": '40px',
                    fontStyle: "normal",
                }}>
                    {t('included_services')}

                </div>

                </Grid>

                {
                    isLg?    <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems:'center',
                        gap:"20px",
                        flexDirection: 'row',
        
                    }} >
        
                        {
        
                          
                            skills.map((item) => {
                                return (
                                    <Product name= {item.title} image= {item.image} description={item.description}/>
                                )
                            })
                        }
                    </Grid> :  <Grid container style={{
                display: 'flex',
                alignItems:'center',
                flexDirection: 'row',


            }} >



                {

                    

                  
                    skills.map((item) => {
                        return (
                            //הוספת השורה הבודדת שבה תהיה קומפוננטת השירות צריכה להיות רק מתחת לגודל md
                            // <Grid item xs={12} style={{
                            //     display: 'flex',
                            //     justifyContent: 'flex-start',
                            //     alignItems:'center',
                            //     gap:"20px",
                            //     flexDirection: 'row',
                            //     paddingRight:'30px'
                    
                            // }} >
                            <Product name= {item.title} image= {item.image} description={item.description} description_small={item.description_small}/>
                            // </Grid>
                        )
                    })
                }

                </Grid>
                }

               
                    



            </Grid>


           

         

            </Grid>

    );

}

